<template>
  <b-card class="shop-policies">
    <b-tabs
      pills
      class="user-profile-info justify-content-center mb-5"
    >
      <b-tab active>
        <template #title>
          <span class="d-inline">{{ $t('Terms & Conditions') }}</span>
        </template>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button variant="primary" size="sm" @click="modalState = true">Ask MOM AI</b-button>
          </b-col>
          <b-col
            cols="12"
            md="12"
            class="show-toolbar-ck"
          >
            <b-form-group :label="$t('Enter Terms and Conditions')">
              <ckeditor
                v-model="dataObj.termsAndCondition"
                :editor="editor"
              />
            </b-form-group>
          </b-col>
          <div class="col-md-12 mt-3 text-center">
            <button
              class="btn btn-primary"
              @click="saveShopStaticPage('termsAndCondition', 'terms')"
            >
              {{ $t('Save') }}
            </button>
          </div>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="d-inline">{{ $t('Cancellation and Refund Policy') }}</span>
        </template>
        <div class="wrap-stripe-config">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="show-toolbar-ck"
            >
              <b-form-group :label="$t('Enter Cancellation and Refund Policy')">
                <ckeditor
                  v-model="dataObj.refundPolicy"
                  :editor="editor"
                />
              </b-form-group>
            </b-col>
            <div class="col-md-12 mt-3 text-center">
              <button
                class="btn btn-primary"
                @click="saveShopStaticPage('refundPolicy', 'refund_policy')"
              >
                {{ $t('Save') }}
              </button>
            </div></b-row>
        </div>
      </b-tab>
    </b-tabs>
    <SingleTemplateGenerate :modalState="modalState" :templateSlug="templateSlug" @modalClosed="modalState = false" @useThisText="dataObj.termsAndCondition = $event" />
  </b-card>
</template>

<script>
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useStoreUi } from './useStore'
import { useProviderUi } from '../provider/useProvider'
import SingleTemplateGenerate from '@/components/ai/SingleTemplateGenerate.vue'
export default {
  components: {
    SingleTemplateGenerate
  },
  data() {
    return {
      editor: ClassicEditor,
      dataObj: {
        refundPolicy: '',
        termsAndCondition: '',
      },
      templateSlug: 'website-terms-and-conditions',
      modalState: false
    }
  },
  computed: {
    storeInfo() {
      return window.SuperApp.getters.userInfo()
    },
  },
  mounted() {
    this.getShopStaticPage()
  },
  methods: {
    saveShopStaticPage(pageType, param) {
      showLoader()
      const { saveShopStaticPage } = useStoreUi()
      const { saveProviderStaticPage } = useProviderUi()

      const typeCall = this.$route.meta.type === 'provider' ? saveProviderStaticPage : saveShopStaticPage

      typeCall({
        [param]: this.dataObj[pageType],
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Save successfully')
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getShopStaticPage() {
      showLoader()
      const { getShopStaticPage } = useStoreUi()
      const { getProviderStaticPage } = useProviderUi()

      const typeCall = this.$route.meta.type === 'provider' ? getProviderStaticPage : getShopStaticPage

      typeCall('?terms&refund_policy')
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.dataObj = {
              refundPolicy: data.responseData.refund_policy,
              termsAndCondition: data.responseData.terms,
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style scoped>
.button-type-style {
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-weight: 300;
  cursor: pointer;
}

.button-type-style.active {
  background: #7367f0 !important;
}

.button-type-style.active label {
  color: #fff;
}
</style>

<style>
.shop-policies .user-profile-info ul.nav.nav-pills {
  justify-content: center;
}
</style>
